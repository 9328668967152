#mainRegister {
  > .app-content {
    padding-top: 2rem !important;
    padding-bottom: 4rem !important;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
}
.va-wrapper {
  margin-bottom: 1rem;
  .va-number {
    font-weight: bold;
    font-size: 1.3em;
  }
}
